import TextField from "@mui/material/TextField";
import { useController, useFormContext } from "react-hook-form";

const Input = ({ name, control, inputProps, ...rest }) => {
  const { control: controlContext } = useFormContext();
  const {
    field: { ref, ...inputPropsForm },
  } = useController({
    name,
    control: control ?? controlContext,
  });

  return (
    <TextField
      {...inputPropsForm}
      inputRef={ref}
      {...rest}
      inputProps={{ ...inputProps }}
    />
  );
};

export default Input;
