import React from 'react';
import { Line } from 'react-chartjs-2';

const LineChart = ({
  data,
  width,
  height
}) => {
  
  const options = {
    scales: {
      y: {
        display: false
      }
    },
    plugins: {
      legend: {
        display: false
      },
    }
  };

  return <Line data={data} options={options} width={width} height={height} />;
};

export default LineChart;