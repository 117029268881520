import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import IconButton from "@mui/material/IconButton";
import useStyles from "./styles";
import Indicator from "../../components/Indicator/Indicator";
import { useEffect, useState } from "react";

const ContractSituation = (props) => {
  const {mockData, isTransition, isNavigate} = props
  const classes = useStyles();
  const [data, setData] = useState(mockData[0]);
  const [totalSteps, setTotalSteps] = useState(mockData.length);
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep((oldState) => oldState + 1);
  };
  const handleBacktStep = () => {
    setCurrentStep((oldState) => oldState - 1);
  };

  useEffect(() => {
    setData(mockData[currentStep]);
  }, [currentStep]);

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4" className={classes.bold}>
              {data.title}
            </Typography>
            {isTransition && (
              <Grid item xl={3} lg={3} md={5} sm={6} xs={11}>
                <Grid container justifyContent="flex-end">
                  <Grid item xl={2} lg={3} md={2} sm={2} xs={2}>
                    <IconButton
                      disabled={currentStep === 0}
                      onClick={handleBacktStep}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xl={2} lg={3} md={2} sm={2} xs={2}>
                    <IconButton
                      disabled={currentStep === totalSteps - 1}
                      onClick={handleNextStep}
                    >
                      <ArrowForwardOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container spacing={1}>
            {data.ind.map((item, key) => (
              <Indicator
                isNavigate={isNavigate}
                key={key}
                plain={item.description}
                score={item.percent}
                title={item.label}
                color={item.color}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContractSituation;
