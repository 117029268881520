import React from "react";
import { Grid, Typography } from "@material-ui/core";

import useStyles from "./styles";

const IndicatorCard = (props) => {
  const classes = useStyles();
  return (
      <Grid container className={classes.container}>
        <Grid item xs={8}>
          <Grid container>
            <Grid
              item
              xs={12}
              className={classes.title}
            >
              <Typography>{props.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item className={classes.label}>
                  <Typography variant="h5" color="primary" className={classes.bold}>
                    {props.label}
                  </Typography>
                </Grid>
                <Grid item className={classes.unitMeasurement}>
                  <Typography variant="h5" color="primary" className={classes.bold}>
                    {props.unit}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100%" }}>
            {props.icon}
          </Grid>
        </Grid>
      </Grid>
  );
};

export default IndicatorCard;
