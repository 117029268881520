import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BorderLinearProgress from "../BorderLinearProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import IconButton from "@mui/material/IconButton";
import useStyles from "./styles";

const data = [
  {
    title: "Ativos",
    ind: [
      {
        id: 1,
        color: "primary",
        value: 32,
        currentValue: 114,
        total: 354,
        percentageOfTotal: 32,
        label: "Óleos e Filtros",
      },
      {
        id: 2,
        color: "error",
        value: 19,
        currentValue: 67,
        total: 354,
        percentageOfTotal: 19,
        label: "Filtros",
      },
      {
        id: 3,
        color: "success",
        value: 19,
        currentValue: 68,
        total: 354,
        percentageOfTotal: 19,
        label: "Pleno",
      },
      {
        id: 4,
        color: "warning",
        value: 30,
        currentValue: 105,
        total: 354,
        percentageOfTotal: 30,
        label: "Preventivo",
      },
    ],
  },
  {
    title: "Cancelados",
    ind: [
      {
        id: 0,
        color: "error",
        value: 35,
        currentValue: 5,
        total: 14,
        percentageOfTotal: 35,
        label: "Filtros",
      },
      {
        id: 1,
        color: "primary",
        value: 29,
        currentValue: 4,
        total: 14,
        percentageOfTotal: 29,
        label: "Óleos e Filtros",
      },
      {
        id: 2,
        color: "warning",
        value: 7,
        currentValue: 1,
        total: 14,
        percentageOfTotal: 7,
        label: "Preventivo",
      },

      {
        id: 3,
        color: "success",
        value: 29,
        currentValue: 4,
        total: 14,
        percentageOfTotal: 29,
        label: "Pleno",
      },
    ],
  },
];

const ContractType = () => {
  const [selectedData, setSelectedData] = useState(data[0]);
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);

  const getNextDataStep = () => setCurrentStep(1);

  const getBackDataStep = () => setCurrentStep(0);

  useEffect(() => {
    setSelectedData(data[currentStep]);
  }, [currentStep]);
  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4" className={classes.bold}>
              {selectedData.title}
            </Typography>
            <Grid item xl={5} lg={5} md={5} sm={6} xs={11}>
              <Grid container justifyContent="flex-end">
                <Grid item xl={2} lg={3} md={2} sm={2} xs={2}>
                  <IconButton disabled={currentStep === 0} onClick={getBackDataStep}>
                    <ArrowBackIcon />
                  </IconButton>
                </Grid>
                <Grid item xl={2} lg={3} md={2} sm={2} xs={2}>
                  <IconButton
                    disabled={currentStep === 1}
                    onClick={getNextDataStep}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container spacing={5}>
            {selectedData.ind.map((item) => (
              <Grid item xl={12} lg={12} sm={12} md={12} xs={12} key={item.id}>
                <Grid container>
                  <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xl={3} lg={3} sm={4} md={4} xs={5}>
                        <Typography align="left">{item.label}</Typography>
                      </Grid>
                      <Grid item xl={4} lg={4} sm={5} md={5} xs={5}>
                        <Typography align="right" className={classes.info}>
                          {item.currentValue} de {item.total} ({item.percentageOfTotal}%)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={item.value}
                      color={item.color}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ContractType;
