import { createContext, useState } from "react";

export const StoreContext = createContext({
  loggedIn: false,
  setLoggedIn: () => {},
  setLoggedOff: () => {},
});

const StoreProvider = ({ children }) => {
  const [login, setLogin] = useState(false);

  const setLoggedIn = () => setLogin(true);

  const setLoggedOff = () => setLogin(false);

  return (
    <StoreContext.Provider
      value={{ loggedIn: login, setLoggedIn, setLoggedOff }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;
