import tinycolor from "tinycolor2";

const primary = "#00529C";
const primaryAlmostWhite = "#1756830a";
const primaryExtraLight= "#70BCFF";
const primaryLight = "#0A8DFF";
const primaryDark = "#003666";
const primaryExtraDark = "#00213D";
const secondary = "#FFE009";
const secondaryLight = "#fff60a";
const secondaryDark = "#ffb90a";
const black = "#000000";
const white = "#FFFFFF";
const gray = "#818894";
const grayExtraLight = "#F1F1F1";
const grayLight = "#B5BCC3";
const grayExtraDark = "#424448";
const grayAlmostLight = "#f8f9fa";
const background = "#F6F7F9";
const warning = "#FFE009";
const success = "#4BBA24";
const info = "#0A8DFF";
const error = "#CE2727";
const tertriary = "#611784";

const lightenRate = 7.5;
const darkenRate = 15;

export default {
  palette: {
    primary: {
      main: primary,
      almostWhite: primaryAlmostWhite,
      extraLight: primaryExtraLight,
      light: primaryLight,
      dark: primaryDark,
      extraDark: primaryExtraDark,
      contrastText: white,
    },
    secondary: {
      main: secondary,
      light: secondaryLight,
      dark: secondaryDark,
      contrastText: black,
    },
    tertriary: {
      main: tertriary,
      light: tinycolor(tertriary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(tertriary)
        .darken(darkenRate)
        .toHexString(),
    },
    error: {
      main: error,
      light: tinycolor(error)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString(),
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
      contrastText: white,
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: grayExtraDark,
      secondary: gray,
      hint: grayLight,
    },
    textPrimary: {
      main: primary,
      light: primaryLight,
      dark: primaryDark,
    },
    background: {
      default: background,
      light: background,
    },
    gray: {
      default: gray,
      grayAlmostLight: grayAlmostLight,
      extraLight: grayExtraLight,
      light: grayLight,
      extraDark: grayExtraDark 
    },
    white: white,
    black: black
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: `${background} !important`,
          "&:focus": {
            backgroundColor: background,
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: background,
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: white,
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        paddingLeft: 24
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 0
      }
    },
    MuiListSubheader: {
      root: {
        lineHeight: '40px'
      }
    }
  },
};
