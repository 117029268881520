import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import LineChart from "../LineChart/LineChart";
import { useTheme } from "@mui/styles";
import Color from "color";

const HealthContractCard = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  const data = [
    {
      labels: ["", "", ""],
      datasets: [
        {
          label: "",
          data: [80, 280, 354],
          backgroundColor: theme.palette.success.main,
          borderColor: Color(theme.palette.success.main).alpha(0.2).string(),
        },
      ]
    }, {
      labels: ["", "", ""],
      datasets: [
        {
          label: "",
          data: [20, 54.4, 272],
          fill: true,
          backgroundColor: Color(theme.palette.success.main).alpha(0.2).string(),
          borderColor: theme.palette.success.main,
        }
      ]
    }, {
      labels: ["", "", ""],
      datasets: [
        {
          label: "",
          data: [10, 30.6, 34],
          fill: true,
          backgroundColor: Color(theme.palette.error.main).alpha(0.2).string(),
          borderColor: theme.palette.error.main
        }
      ]
    }, {
      labels: ["", "", ""],
      datasets: [
        {
          label: "",
          data: [9, 30.6, 34],
          fill: true,
          backgroundColor: Color(theme.palette.warning.main).alpha(0.2).string(),
          borderColor: theme.palette.warning.main
        }
      ]
    }
  ];
  
  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Typography variant="h4" className={classes.bold}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12} marginTop={1}>
          <Grid container spacing={2}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <Typography variant="h6" color="success.main">
                &#9673; Dentro do Plano - 272  &nbsp;80%
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
              <LineChart data={data[1]} width={120} height={30} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <Typography variant="h6" color="error.main">
                &#9673; Acima do Plano - 34 &nbsp;10%
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
              <LineChart data={data[2]} width={120} height={30} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <Typography variant="h6" color="warning.dark">
                &#9673; Abaixo do Plano - 34 &nbsp;10%
              </Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
              <LineChart data={data[3]} width={120} height={30} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HealthContractCard;
