import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import Indicator from "../../components/Indicator/Indicator";
import { LocalGasStation, Speed } from '@mui/icons-material';
import IndicatorCard from "./components/IndicatorCard";

const HealthContractComponent = (props) => {
  const {mockData, isNavigate} = props
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h4" className={classes.bold}>
              {mockData[0].title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xl={10} lg={9} md={12} sm={12} xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            {mockData[0].ind.map((item, key) => (
              <Indicator
                isNavigate={isNavigate}
                key={key}
                plain={item.description}
                score={item.percent}
                title={item.label}
                color={item.color}
              />
            ))}
          </Grid>
        </Grid>
        <Grid item xl={2} lg={3} md={12} sm={12} xs={12}>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <IndicatorCard
                title="Média de Consumo"
                label={"1.89 km/l"}
                icon={<LocalGasStation />}
              />
            </Grid>
            <Grid item xs={12}>
              <IndicatorCard
                title="Velocidade Média"
                label={"82 km/h"}
                icon={<Speed />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HealthContractComponent;
