import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    padding: 25,
    minHeight: "100%",
  },
  title: { fontWeight: "bold" },
  info: { opacity: 0.5 },
  bold: { 
    fontWeight: "bold !important" 
  },
}));
