import Grid from "@mui/material/Grid";
import AllContractCard from "../../components/AllContractCard";
import ContractSituation from "../../components/ContractSituation";
import ContractType from "../../components/ContractType";
import DueDateCard from "../../components/DueDateCard";
import HealthContractCard from "../../components/HealthContractCard";
import { mockData } from "../../helpers/mocks/contractSituation/mockIrregularCard";

function Dashboard() {
  return (
    <Grid container spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Grid item xl={7} lg={7} md={7} sm={12} xs={12} sx={{ display: "grid", gridAutoFlow: "row" }}>
        <AllContractCard />
      </Grid>
      <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
        <ContractType />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ContractSituation mockData={mockData} isTransition isNavigate />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <HealthContractCard title={"Performance dos Contratos"}/>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <DueDateCard title={"À Vencer"}/>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
