import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  container: {
    padding: 2,
    height: "100%",
    margin: 10
  },
  title: {
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: 400,
  },
  label: {
    color: "#12588A",
    fontSize: "26px",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },
  unitMeasurement: {
    fontSize: "13px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    marginLeft: "5px",
  },
  bold: {
    fontWeight: "bold",
  }
}));
