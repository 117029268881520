import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    padding: 25,
  },
  bold: { 
    fontWeight: "bold !important" 
  },
  info: { 
    opacity: 0.5 
  },
  success: {
    color: theme.palette.success.main,
  },
  total: {
    fontSize: "4rem !important",
  }
}));
