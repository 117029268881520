import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  logotype: {
    color: theme.palette.black,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  logoIconButton: {
    padding: theme.spacing(1),
    borderRadius: "0px !important",
    backgroundColor: `${theme.palette.white} !important`,
    "& :hover": {
      filter: "drop-shadow(2px 3px 2px rgb(0 0 0 / 0.2))",
    },
  },
  logoHeader: {
    width: "150px",
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
