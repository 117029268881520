import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold"
  },
  subtitle: {
    textAlign: "center"
  },
  label: {
    fontSize: '15px'
    , fontWeight: 'bold'
  },
  button: {
    backgroundColor: `${theme.palette.white} !important`,
  },
  responsiveContainer: {
    "& .recharts-wrapper": {
      cursor: "pointer !important"
    }
  }
}));
