import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import LineChart from "../LineChart/LineChart";
import classNames from "classnames";
import Color from "color";
import { useTheme } from "@mui/styles";

const AllContractCard = () => {
  const classes = useStyles();
  const theme = useTheme();

  const data = [
    {
      labels: ["Agosto", "Setembro", "Outubro"],
      datasets: [
        {
          label: "",
          data: [180, 300, 354],
          backgroundColor: theme.palette.success.main,
          borderColor: Color(theme.palette.success.main).alpha(0.2).string(),
        },
      ]
    }, {
      labels: ["Agosto", "Setembro", "Outubro"],
      datasets: [
        {
          label: "",
          data: [20, 144, 340],
          fill: true,
          backgroundColor: Color(theme.palette.success.main).alpha(0.2).string(),
          borderColor: theme.palette.success.main,
        }
      ]
    }, {
      labels: ["Agosto", "Setembro", "Outubro"],
      datasets: [
        {
          label: "",
          data: [7, 16, 14],
          fill: true,
          backgroundColor: Color(theme.palette.error.main).alpha(0.2).string(),
          borderColor: theme.palette.error.main
        }
      ]
    }, {
      labels: ["Agosto", "Setembro", "Outubro"],
      datasets: [
        {
          label: "",
          data: [1, 1.18, 1.17],
          fill: true,
          backgroundColor: Color(theme.palette.primary.main).alpha(0.2).string(),
          borderColor: theme.palette.primary.main
        }
      ]
    }
  ];

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography variant="h4" className={classes.bold}>
                Contratos com conectividade
              </Typography>
              <Typography className={classNames([classes.bold, classes.success, classes.total])}>
                354
              </Typography>
              <Typography variant="h5" className={classes.success}>
                +15% nos últimos 30 dias
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <LineChart data={data[0]} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12} marginTop={3}>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                  <Typography variant="h5" className={classes.bold}>
                    Ativos
                  </Typography>
                  <Typography variant="h1" className={classNames([classes.bold, classes.success])}>
                    340
                  </Typography>
                  <Typography variant="h5" className={classes.success}>
                    + 60%
                  </Typography>
                </Grid>
                <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
                  <LineChart data={data[1]} width={150} height={100} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                  <Typography variant="h5" className={classes.bold}>
                    Cancelados
                  </Typography>
                  <Typography variant="h1" color="error" className={classes.bold}>
                    14
                  </Typography>
                  <Typography variant="h5" color="error">
                    - 8%
                  </Typography>
                </Grid>
                <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
                  <LineChart data={data[2]} width={150} height={100} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
                  <Typography variant=" " className={classes.bold}>
                    Taxa de Cancelamento
                  </Typography>
                  <Typography variant="h1" color="primary" className={classes.bold}>
                    1.17%
                  </Typography>
                  <Typography variant="h5" color="primary">
                    - 1%
                  </Typography>
                </Grid>
                <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
                  <LineChart data={data[3]} width={150} height={100} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AllContractCard;
