import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";

// custom styles
import "./custom.css";

// components
import DashboardContent from "./Layout";

// pages
import Login from "../pages/login";
import { StoreContext } from "../context/UserContext";

export default function App() {
  const { loggedIn } = useContext(StoreContext);
  
  return (
    <Router>
      <ToastContainer />
      <Route exact path="/">
        {loggedIn ? <Redirect to="/daf" /> : <Redirect to="/login" />}
      </Route>
      <Route component={Login} path="/login" />
      <Route component={DashboardContent} path="/daf" />
    </Router>
  );
}
