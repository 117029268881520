import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";
import BorderLinearProgress from "../BorderLinearProgress";

const DueDateCard = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container>
            <Typography variant="h4" className={classes.bold}>
              {props.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12} className={classes.total}>
              <Typography variant="h3" color="error" className={classes.bold}>
                57 Contratos
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
              <Typography variant="h6">
                30 Contratos Km
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={93}
                color={'success'}
              />
            </Grid>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
              <Typography variant="h6">
                27 Contratos Data
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={45}
                color={'warning'}
              />
            </Grid>
          </Grid> 
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DueDateCard;
