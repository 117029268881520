export const mockData = [
    {
      title: "Irregulares",
      ind: [
        {
          id: 1,
          color: "warning",
          percent: 8.5,
          label: "Manutenção vencida",
          description: "29 planos",
        },
        {
          id: 2,
          color: "success",
          percent: 5.27,
          label: "Excesso fator de carga",
          description: "18 planos",
        },
        {
          id: 3,
          color: "danger",
          percent: 4.39,
          label: "Fora da pavimentação",
          description: "15 planos",
        },
        {
          id: 4,
          color: "success",
          percent: 2.05,
          label: "Divergência topografia",
          description: "7 planos",
        },
        {
          id: 5,
          color: "danger",
          percent: 20.23,
          label: "Fora do limite de velocidade",
          description: "69 planos",
        },
        {
          id: 6,
          color: "danger",
          percent: 17,
          label: "Acima do km contratado",
          description: "58 planos",
        },
        {
          id: 7,
          color: "warning",
          percent: 12.32,
          label: "Excesso de consumo",
          description: "42 planos",
        }
      ],
    },
    {
      title: "Regulares",
      ind: [
        {
          id: 1,
          color: "warning",
          percent: 48,
          label: "Manutenção vencida",
          description: "29 planos",
        },
        {
          id: 2,
          color: "success",
          percent: 30,
          label: "Excesso fator de carga",
          description: "18 planos",
        },
        {
          id: 3,
          color: "danger",
          percent: 5,
          label: "Fora da pavimentação",
          description: "15 planos",
        },
        {
          id: 4,
          color: "success",
          percent: 32,
          label: "Divergência topografia",
          description: "9 planos",
        },
        {
          id: 5,
          color: "danger",
          percent: 48.3,
          label: "Fora do limite de velocidade",
          description: "69 planos",
        },
        {
          id: 6,
          color: "danger",
          percent: 6.7,
          label: "Acima do km contratado",
          description: "58 planos",
        },
        {
          id: 7,
          color: "warning",
          percent: 22.32,
          label: "Excesso de consumo",
          description: "42 planos",
        }
      ],
    },
    
  ];