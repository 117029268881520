export const mockData = [
    {
      title: "Performance do contrato",
      ind: [
        {
          id: 1,
          color: "success",
          percent: 8.50,
          label: "Km fim do contrato",
          description: "8.200 km percorridos",
        },
        {
          id: 2,
          color: "warning",
          percent: 60,
          label: "Dias fim do contrato",
          description: "268",
        },
        {
          id: 3,
          color: "danger",
          percent: 70,
          label: "Fator de carga",
          description: "",
        },
        {
          id: 4,
          color: "danger",
          percent: 95,
          label: "Pavimentação",
          description: "",
        },
        {
          id: 5,
          color: "warning",
          percent: 90,
          label: "Topografia",
          description: "",
        },
        // {
        //   id: 6,
        //   color: "success",
        //   percent: 67,
        //   label: "Média consumo",
        //   description: "",
        // },
        // {
        //   id: 7,
        //   color: "warning",
        //   percent: 12.32,
        //   label: "Excesso de velocidade",
        //   description: "",
        // },
        // {
        //   id: 8,
        //   color: "warning",
        //   percent: 27.32,
        //   label: "Km contratada",
        //   description: "",
        // }
      ],
    }
  ];