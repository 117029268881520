import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    padding: 25,
    minHeight: "100%",
    display: 'flex'
  },
  bold: { 
    fontWeight: "bold !important" 
  },
  total: {
    paddingTop: "0 !important"
  }
}));
