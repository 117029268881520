import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    padding: 25,
  },
  bold: { 
    fontWeight: "bold !important" 
  },
}));
