import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { mockData } from '../../helpers/mocks/contractSituation/mockContractHealth'
import HealthContractComponent from "../../components/HealthContractComponent";
const fakeData = [
  {
    title: "Dados do Plano Contratado",
    data: [
      { title: "TIPO", value: "Preventivo" },
      { title: "MÉDIA ESPERADA", value: "2 a 2.99 Km/L" },
      { title: "PBTC PREVISTO", value: 50 },
      { title: "TIPO DE IMPLEMENTO", value: "Vanderleia 53,0 T" },
      { title: "VENCIMENTO POR KM", value: 610.0 },
      { title: "QUANTIDADE DE VEÍCULOS", value: 1 },
      { title: "KM PREVISTO MÊS", value: 10.0 },
      { title: "VELOCIDADE MÁXIMA", value: "70 - 90" },
      { title: "PAVIMENTAÇÃO PREVISTA", value: "Asfalto 100%" },
      { title: "VENCIMENTO PELA DATA", value: "31/03/2021" },
      { title: "TIPO DE CARGA", value: "Slider" },
      { title: "FATOR DE CARGA(%)", value: "50%" },
      { title: "TIPOGRAFIA", value: "Pred. Plana" },
      { title: "SEVERIDADE", value: "GRAU II" },
    ],
  },
  {
    title: "Dados do Veículo",
    data: [
      { title: "CHASSI", value: "184XGCA-ACAW-XX" },
      { title: "PLACA", value: "HJN-4850" },
      { title: "MARCA", value: "DAF" },
      { title: "MODELO", value: "DAF-1236" },
      { title: "CONFIGURAÇÃO DE EIXO", value: "6x4" },
      { title: "CAPACIDADE TANQUE", value: 300 },
      { title: "RELAÇÃO DIFERENCIAL", value: "6x4" },
    ],
  },
  {
    title:
      "Dados para envio das Unidades Eletrônicas & Gestor Responsável da Telemetria na Transportadora",
    data: [
      { title: "CONCESSIONÁRIO P/ ENVIO", value: "DAF Birigui - Cascavel" },
      { title: "RESPONSÁVEL PELA CONCESSIONÁRIA", value: "Rafael Sabini" },
      { title: "CEP", value: "35030-590" },
      { title: "ENDEREÇO", value: "Rua Rodolfo de Abreu, 404" },
      { title: "ESTADO", value: "MG" },
      { title: "CIDADE", value: "GOVERNADOR VALADARES" },
      { title: "BAIRRO", value: "SÃO PAULO" },
      { title: "GESTOR", value: "JEAN" },
      { title: "EMAIL GESTOR", value: "jean@mail.com" },
    ],
  },
  {
    title: "Dados do Transportador",
    data: [
      { title: "CONCESSIONÁRIA", value: "DAF - Cascavel" },
      { title: "NÚMERO DO CONTRATO", value: "1548090" },
      { title: "CNPJ", value: "35.350.600/0001-50" },
      { title: "INSCRIÇÃO MUNICIPAL/ESTADUAL", value: "1518108084808" },
      { title: "RAZÃO SOCIAL", value: "GoBrax Transportes" },
      { title: "NOME FANTASIA", value: "Siga Bem Caminhoneiro" },
      { title: "REPRESENTANTE LEGAL", value: "Amauri Junior" },
      { title: "CPF", value: "123.456.456-80" },
      { title: "EMAIL", value: "amauri@mail.com" },
      { title: "TELEFONE", value: "(33) 998765-4870" },
    ],
  },
];

const ContractHealth = () => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState(fakeData[0]);
  const [totalSteps, setTotalSteps] = useState(fakeData.length);
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep((oldState) => oldState + 1);
  };
  const handleBacktStep = () => {
    setCurrentStep((oldState) => oldState - 1);
  };

  useEffect(() => {
    setData(fakeData[currentStep]);
  }, [currentStep]);

  return (
    <Grid container spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <HealthContractComponent mockData={mockData} />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={1}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper style={{ padding: "20px 30px" }}>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xl={10} lg={9} md={8} sm={7} xs={5}>
                      <Typography variant="h4" className={classes.bold}>
                        {data.title}
                      </Typography>
                    </Grid>
                    <Grid item xl={2} lg={3} md={4} sm={5} xs={7}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <IconButton
                            disabled={currentStep === 0}
                            onClick={handleBacktStep}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <IconButton
                            disabled={currentStep === totalSteps - 1}
                            onClick={handleNextStep}
                          >
                            <ArrowForwardOutlinedIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {data.data.map((item, key) => (
                  <Grid item xl={2} lg={2} md={3} sm={6} xs={12} key={key}>
                    <Paper
                      elevation={3}
                      style={{ padding: 10, minHeight: "100%" }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="caption">
                            {item.title}
                          </Typography>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="h6" color="primary">
                            {item.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid container justifyContent="flex-end">
          <Grid item xs={1}>
            <Button
              size="large"
              variant="contained"
              fullWidth
              onClick={() => history.goBack()}
            >
              Voltar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContractHealth;
