import { makeStyles } from "@mui/styles";
import DafBg from "../../images/daf-1.png";

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  gridFormContainer: {
    display: "flex", 
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    zIndex: 1,
  },
  loginBox: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  boxFormContainer: {
    marginTop: theme.spacing(2),
  },
  bgImage: {
    backgroundImage: `url(${DafBg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  logoGrid: {
    alignItems: "center", 
    justifyContent: "center",
    flexDirection: "column"
  },
  logo: {
    width: "260px",
  },
  submitBtn: {
    marginTop: `${theme.spacing(2)} !important`, 
    marginBottom: `${theme.spacing(2)} !important`, 
  },
  copyright: {
    marginTop: `${theme.spacing(5)} !important`, 
  }
}));
