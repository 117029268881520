import { useForm, FormProvider, useFormState } from "react-hook-form";
import { useHistory } from "react-router";
import { useContext } from "react";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import Copyright from "../../components/Copyright";
import Input from "../../components/react-hook-form/input";
import schemaValidation from "../../helpers/validations/yup/login";
import LogoGobrax from "../../images/gobrax.svg";
import useStyles from "./styles.js";
import { StoreContext } from "../../context/UserContext";

export default function Login() {
  const classes = useStyles();
  const history = useHistory();

  const { setLoggedIn } = useContext(StoreContext);
  const defaultValues = {
    email: "",
    password: "",
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const { errors } = useFormState({ control: methods.control });

  const onSubmit = (data) => {
    setLoggedIn();
    history.push("/");
  };

  return (
    <FormProvider {...methods}>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={4}
          square={true}
          className={classes.gridFormContainer}
        >
          <Box className={classes.loginBox}>
            <Grid container className={classes.logoGrid}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <img
                  className={classes.logo}
                  src={LogoGobrax}
                  alt="Logo Gobrax"
                />
              </Grid>
            </Grid>
            <Box
              component="form"
              noValidate
              onSubmit={methods.handleSubmit(onSubmit)}
              className={classes.boxFormContainer}
            >
              <Input
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                helperText={errors.email?.message}
                error={errors.email && true}
              />
              <Input
                margin="normal"
                fullWidth
                name="password"
                label="Senha *"
                type="password"
                autoComplete="current-password"
                helperText={errors.password?.message}
                error={errors.password && true}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Lembrar"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submitBtn}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Esqueceu sua senha?
                  </Link>
                </Grid>
              </Grid>
              <Copyright className={classes.copyright} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={false} sm={4} md={7} className={classes.bgImage} />
      </Grid>
    </FormProvider>
  );
}
