import { Grid, Paper, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "./styles.js";
import { useHistory } from "react-router-dom";

const Monitoring = () => {
  const classes = useStyles();
  const breakPoint = useMediaQuery("(min-width:750px)");
  const history = useHistory();

  return (
    <Grid container className={classes.root}>
      <Grid item xl={6} lg={6} md={6} sm={!breakPoint ? 12 : 6} xs={12}>
        <Paper className={classes.paperContainer}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography paragraph variant="h1">
                Bem vindo, Thiago!
              </Typography>
              <Typography variant="h5">
                Você está no menu geral de monitoramento, conheça um pouco 
                mais das funcionalidades clicando abaixo:
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.btContent}>
            <Stack spacing={2}>
                <Button 
                  size="large" 
                  variant="contained" 
                  onClick={() => history.push("/daf/dashboard", { title: "Dashboard" })}
                  endIcon={<ArrowForwardIcon />}
                >
                  Acesse o painel e veja de maneira geral os números dos contratos ativos/cancelados, tipos de contrato, saúde do veículo etc.
                </Button>
                <Button 
                  size="large" 
                  variant="contained" 
                  onClick={() => history.push("/daf/maintenance-plan", { title: "Plano de Manutenção" })}
                  endIcon={<ArrowForwardIcon />}
                >
                  Consulte os planos de manutenção e veja em detalhes as informações do contrato e a saúde do veículo
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {breakPoint && (
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={4}
          className={classes.bgImage}
        />
      )}
    </Grid>
  );
};

export default Monitoring;
