export default [
  {
    id: 1,
    agent: "Cisne Branco",
    contract: "15",
    cnpj: "06.767.974/0001-81",
    contractHelth: 60,
    irregular: "SIM",
    unity: 154045,
    type: "PREVENTIVO",
    status: "ATIVO",
  },
  {
    id: 2,
    agent: "Costa Teixeira",
    contract: "1",
    cnpj: "78.807.427/0001-93",
    contractHelth: 20,
    irregular: "SIM",
    unity: 154878,
    type: "ÓLEOS E FILTROS",
    status: "MANUTENÇÃO VENCIDA",
  },
  {
    id: 3,
    agent: "Dalastra Transportadora",
    contract: "9",
    cnpj: "75.303.222/0001-82",
    contractHelth: 50,
    irregular: "NÃO",
    unity: 147852,
    type: "PREVENTIVO",
    status: "ATIVO",
  },
  {
    id: 4,
    agent: "Demarco Transportes",
    contract: "7",
    cnpj: "12.828.892/0001-00",
    contractHelth: 43,
    irregular: "SIM",
    unity: 953654,
    type: "ÓLEOS E FILTROS PLUS",
    status: "ATIVO",
  },
  {
    id: 5,
    agent: "Paganini Logística",
    contract: "12",
    cnpj: "12.663.272/0001-50",
    contractHelth: 78,
    irregular: "NÃO",
    unity: 987665,
    type: "PLENO",
    status: "MANUTENÇÃO VENCIDA",
  },
];
