import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  listItem: {
    "& .MuiListItemIcon-root": {
      minWidth: "60px !important",
      paddingLeft: "5px",
    }    
  }
}));
