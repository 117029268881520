import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import { useHistory } from "react-router-dom";
import useStyles from "./styles.js";

export const MainListItems = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div>
      <ListItem button onClick={() => history.push("/daf", {title: "Monitoramento"})} className={classes.listItem}>
        <ListItemIcon>
          <VisibilityIcon />
        </ListItemIcon>
        <ListItemText primary="Monitoramento" />
      </ListItem>
      <ListItem button onClick={() => history.push("/daf/dashboard", {title: "Painel"})} className={classes.listItem}>
        <ListItemIcon>
          <EqualizerIcon />
        </ListItemIcon>
        <ListItemText primary="Painel" />
      </ListItem>
      <ListItem button onClick={() => history.push("/daf/maintenance-plan", {title: "Plano de Manutenção"})} className={classes.listItem}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="Plano de Manutenção" />
      </ListItem>
    </div>
  );
};
