import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Label } from "recharts";
import { Box, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import useStyle from "./styles";
import { useHistory } from "react-router-dom";

export default function CircularProgressWithLabel({
  title,
  score,
  plain,
  color,
  isNavigate
}) {
  const theme = useTheme();
  const classes = useStyle();
  const history = useHistory();

  const makePieColor = (value) => {
    switch (value) {
      case "success":
        return theme.palette.success.main;
      case "warning":
        return theme.palette.warning.main;
      case "danger":
        return theme.palette.error.main;
      case "primary":
        return theme.palette.primary.main;
      case "":
        return theme.palette.primary.main;
      default:
        return "#f1f1f1";
    }
  };

  const completValueScore = 100 - score;
  const pieData = [
    { name: "indicatorValue", value: score, color: makePieColor(color) },
    { name: "complementValue", value: completValueScore, color: "#f1f1f1" },
  ];

  return (
    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
      <Box textAlign="center">
        <Button 
          onClick={() => isNavigate ? history.push("/daf/maintenance-plan", {title: "Plano de Manutenção"}) : null}
          className={classes.button}
        >
          <Grid container justifyContent="space-around" alignItems="center">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }}>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                align="center"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={10}>
              <ResponsiveContainer
                width="100%"
                height={90}
                className={classes.responsiveContainer}
              >
                <PieChart>
                  <Pie
                    data={pieData}
                    startAngle={90}
                    endAngle={-270}
                    innerRadius={30}
                    outerRadius={40}
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={index} fill={entry.color} />
                    ))}
                    {pieData.map((entry, index) => {
                      return (
                        entry.name === "indicatorValue" && (
                          <Label
                            value={`${score}%`}
                            position="center"
                            className={classes.label}
                            key={index}
                          />
                        )
                      );
                    })}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xl={8} lg={8}>
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
                className={classes.subtitle}
              >
                {plain}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Box>      
    </Grid>
  );
}
