import { makeStyles } from "@mui/styles";
import DafBg from "../../images/daf-1.png";

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paperContainer: {
    height: "100vh", 
    padding: "100px 30px",
    [theme.breakpoints.down("sm")]: {
      height: "110vh", 
      padding: "30px 30px",
    },
  },
  textContent: {
    fontWeight: "bold !important",
    lineHeight: "1.8em !important",
  },
  btContent: {
    marginTop: `${theme.spacing(3)} !important`,
    [theme.breakpoints.down("sm")]: {
      marginTop: `${theme.spacing(1)} !important`,
    },
  },
  bgImage: {
    backgroundImage: `url(${DafBg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));
