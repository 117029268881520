import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import ActionButton from "../../components/actionButton";
import mockData from "./config/table/mockData";
import { useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import useStyles from "./styles";

const MaintenancePlan = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container spacing={2} sx={{ padding: (t) => t.spacing(2) }}>
      <Grid item xs={12}>
        <Grid container sx={{ padding: (t) => t.spacing(3) }} component={Paper}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} marginBottom={2}>
            <Typography variant="h4" className={classes.bold}>
              Planos de Manutenção
            </Typography>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TableContainer component={Paper} elevation={3}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">CONTRATO</TableCell>
                    <TableCell align="center">REPRESENTANTE</TableCell>
                    <TableCell align="center">CNPJ / REPRESENTANTE</TableCell>
                    <TableCell align="center">SAUDE DO CONTRATO</TableCell>
                    <TableCell align="center">IRREGULAR</TableCell>
                    <TableCell align="center">UNIDADE ELETRÔNICA</TableCell>
                    <TableCell align="center">TIPO</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell align="center">AÇÕES</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mockData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center" component="th" scope="row">
                        {row.contract}
                      </TableCell>
                      <TableCell align="center">{row.agent}</TableCell>
                      <TableCell align="center">
                        <div>{row.cnpj}</div>
                        <div style={{ fontWeight: "bold" }}>{row.agent}</div>
                      </TableCell>
                      <TableCell align="center">{row.contractHelth}</TableCell>
                      <TableCell align="center">{row.irregular}</TableCell>
                      <TableCell align="center">{row.unity}</TableCell>
                      <TableCell align="center">{row.type}</TableCell>
                      <TableCell align="center">
                        <Chip
                          label={row.status}
                          color={row.status === "ATIVO" ? "primary" : "error"}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <ActionButton
                          options={[
                            {
                              label: "Ver Detalhes",
                              action: () =>
                                history.push("/daf/contract-health", {title: "Performance do Contrato"}),
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MaintenancePlan;
